<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="开始时间" prop="start_time">
                <el-date-picker
                    v-model="form.start_time"
                    align="right"
                    type="date"
                    placeholder="选择开始时间"
                    value-format="yyyy-MM-dd"
                    class="start_timec"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="end_time">
                <el-date-picker
                    v-model="form.end_time"
                    align="right"
                    type="date"
                    placeholder="选择结束时间"
                    value-format="yyyy-MM-dd"
                    class="end_timec"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="员工" prop="staff_id">
                <el-select
                    v-model="form.staff_id"
                    placeholder="员工搜索"
                    class="staff_idc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="staff in AllStaff"
                        :key="staff.name"
                        :label="staff.name"
                        :value="staff.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')">重置</el-button>
                <el-button plain @click="out_excel" v-if="$_has('生成报表')">生成报表</el-button>
                <common-excel-component
                v-if="$_has('导入考勤')"
                name='导入日考勤记录'
                :header="excel_header"
                @excel_arr="excel_arr"
            ></common-excel-component>
            </el-form-item>
        </el-form>
    </el-card>
</template>

<script>
import { staff_list_request } from '@/network/list.js'
import { attendance_excel_add_request } from '@/network/hrm/Attendance.js'

import CommonExcelComponent from '@/components/common/CommonExcelComponent'

export default {
    name: 'AttendanceSearchBarComponent',
    data() {
        return {
            form: {
                start_time: '',
                end_time: '',
                staff_id: '',
                page: 1,
            },
            AllStaff: [],
            excel_header: [
                {
                    code: 'staff_name',
                    name: '姓名'
                },{
                    code: 'date',
                    name: '日期'
                },{
                    code: 'go_in_time',
                    name: '签到打卡'
                },{
                    code: 'go_out_time',
                    name: '签退打卡'
                }
            ]
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        excel_arr(arr) {
            attendance_excel_add_request({excel: arr})
                .then((s) => {
                    if (s.status === 0) {
                        this.$message.success('导入成功!')
                        this.$emit('search')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        out_excel() {
            this.$emit('out_excel', this.form)
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_all_staff()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonExcelComponent
    },
    watch: {},
}
</script>

<style lang='less'></style>