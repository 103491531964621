<template>
    <div></div>
</template>

<script>

export default {
    name:'',
    data(){
        return {}
    },
    computed:{},
    methods:{
        outExcel() {
                this.$confirm('此操作将导出excel文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.JsonExcel(this.TableName,this.HeaderArr,this.BobyArr)
                }).catch(() => {
                
                });
            },
            JsonExcel(TableName,HeaderArr,BobyArr) {
                var that = this;
                require.ensure([], () => {
                    const { export_json_to_excel } = require('../../assets/js/ExportExcel/Export2Excel.js'); //这里必须使用绝对路径
                    let header_name = []
                    let header_code = []
                    HeaderArr.forEach(v => {
                        header_name.push(v.label)
                        header_code.push(v.prop)
                    })
                    const data = that.formatJson(header_code, BobyArr);
                    export_json_to_excel(header_name, data, TableName);
                })
            },
            TableExcel(TableName,HeaderArr,BobyArr) {
                require.ensure([], () => {
                    let html = `<html><body><table cellspacing = 0 border=1>`
                    html += `<th>`
                    HeaderArr.forEach(th => {
                        html += `<td>` + th.label + `</td>`
                    })
                    html += `</th>`
                    BobyArr.forEach(td => {
                        html += `<tr>`
                        HeaderArr.forEach(v => {
                            html += `<td>` + td[v.prop] + `</td>`
                        })
                        html += `</tr>`
                    })
                    html += `</table></body></html>`

                    let dom = document.createElement('html')
                    dom.innerHTML = html
                    const { export_table_to_excel } = require('../../assets/js/ExportExcel/Export2Excel.js'); //这里必须使用绝对路径
                    export_table_to_excel(dom,TableName);
                })
            },
            formatJson(filterVal, jsonData) {
                return jsonData.map(v => filterVal.map(j => v[j]))
            }
    },
    filters:{},
    props:{
        //文件名
        TableName: {
            type: String,
            default() {
                return '导出表格'
            }
        },
        //表头
        // [
        //     {
        //         label: 表格要显示的表头名,
        //         prop: 代码对应的表头字段
        //     }
        // ]
        HeaderArr: {
            type: Array,
            default() {
                return []
            }
        },
        //数组对象
        BobyArr: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created(){},
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style>
</style>