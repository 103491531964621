<template>
    <div v-loading.fullscreen.lock="loading">
        <attendance-search-bar-component
            @search="get_attendance"
            @out_excel="outExcel"
        ></attendance-search-bar-component>
        <common-table-component
            :col_arr="fcol_arr"
            :table_data="ftable_data"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_attendance"
        ></common-page-component>
        <!-- 导出 -->
        <common-out-put-excel-component
            ref="out_excel"
            :TableName="out_excel_name"
            :HeaderArr="out_excel_heard"
            :BobyArr="out_excel_data"
        ></common-out-put-excel-component>
    </div>
</template>

<script>
import { attendance_index_request,out_excel_request } from '@/network/hrm/Attendance.js'

import AttendanceSearchBarComponent from '@/components/hrm/Attendance/AttendanceSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import CommonOutPutExcelComponent from '@/components/common/CommonOutPutExcelComponent'

export default {
    name: 'AttendanceView',
    data() {
        return {
            loading: true,
            ftotal: 0,
            cond: {
                start_time: '',
                end_time: '',
                staff_id: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'date',
                    label: '日期',
                    minWidth: '100px'
                },{
                    prop: 'staff_name',
                    label: '员工',
                    minWidth: '100px'
                },
                {
                    prop: 'sw',
                    label: '上午',
                    minWidth: '120px',
                    html: (row) => {
                        if(row['sw']==='正常') {
                            return `<div class="cell"><span class="el-tag el-tag--success el-tag--plain">正常</span></div>`
                        } else if(row['sw']==='迟到') {
                            return `<div class="cell"><span class="el-tag el-tag--warning el-tag--plain">迟到</span></div>`
                        } else if(row['sw']==='旷工') {
                            return `<div class="cell"><span class="el-tag el-tag--danger el-tag--plain">旷工</span></div>`
                        } else if(row['sw']==='周末') {
                            return `<div class="cell"><span class="el-tag el-tag--info el-tag--plain">周末</span></div>`
                        } else if(row['sw']==='休假') {
                            return `<div class="cell"><span class="el-tag el-tag--primary el-tag--plain">休假</span></div>`
                        } else if(row['sw']==='出差') {
                            return `<div class="cell"><span class="el-tag el-tag--primary el-tag--plain">出差</span></div>`
                        } else if(row['sw']==='外出') {
                            return `<div class="cell"><span class="el-tag el-tag--primary el-tag--plain">外出</span></div>`
                        } else if(row['sw']==='补签') {
                            return `<div class="cell"><span class="el-tag el-tag--primary el-tag--plain">补签</span></div>`
                        }
                    }
                },
                {
                    prop: 'xw',
                    label: '下午',
                    minWidth: '120px',
                    html: (row) => {
                        if(row['xw']==='正常') {
                            return `<div class="cell"><span class="el-tag el-tag--success el-tag--plain">正常</span></div>`
                        } else if(row['xw']==='早退') {
                            return `<div class="cell"><span class="el-tag el-tag--warning el-tag--plain">早退</span></div>`
                        } else if(row['xw']==='旷工') {
                            return `<div class="cell"><span class="el-tag el-tag--danger el-tag--plain">旷工</span></div>`
                        } else if(row['xw']==='周末') {
                            return `<div class="cell"><span class="el-tag el-tag--info el-tag--plain">周末</span></div>`
                        } else if(row['xw']==='休假') {
                            return `<div class="cell"><span class="el-tag el-tag--primary el-tag--plain">休假</span></div>`
                        } else if(row['xw']==='出差') {
                            return `<div class="cell"><span class="el-tag el-tag--primary el-tag--plain">出差</span></div>`
                        } else if(row['xw']==='外出') {
                            return `<div class="cell"><span class="el-tag el-tag--primary el-tag--plain">外出</span></div>`
                        } else if(row['xw']==='补签') {
                            return `<div class="cell"><span class="el-tag el-tag--primary el-tag--plain">补签</span></div>`
                        }
                    }
                },
                {
                    prop: 'go_in_time',
                    label: '上班卡',
                    minWidth: '90px'
                },
                {
                    prop: 'go_out_time',
                    label: '下班卡',
                    minWidth: '90px'
                }
            ],
            out_excel_heard: [],
            out_excel_data: []
        }
    },
    computed: {
        out_excel_name() {
            const n = new Date();
            const year = n.getFullYear();
            const month = (((n.getMonth()) + 1)+'').length === 1 ? ('0' + ((n.getMonth()) + 1)) : ((n.getMonth()) + 1);
            const date = (n.getDate()+'').length === 1 ? ('0' + n.getDate()) : n.getDate();
            const hour = (n.getHours()+'').length === 1 ? ('0' + n.getHours()) : n.getHours();
            const minute = (n.getMinutes()+'').length === 1 ? ('0' + n.getMinutes()) : n.getMinutes();
            const second = (n.getSeconds()+'').length === 1 ? ('0' + n.getSeconds()) : n.getSeconds();

            return '月考勤' + year +  month + date +  hour +  minute + second
        }
    },
    methods: {
        get_attendance(param = {}) {
            this.loading = true
            this.cond.start_time = param.start_time ?? this.cond.start_time
            this.cond.end_time = param.end_time ?? this.cond.end_time
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            attendance_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        outExcel(param = {}) {
            this.loading = true
            this.cond.start_time = param.start_time ?? this.cond.start_time
            this.cond.end_time = param.end_time ?? this.cond.end_time

            out_excel_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.out_excel_heard = s.result.heard
                        this.out_excel_data = s.result.data
                        this.$refs['out_excel'].outExcel()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_attendance()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        AttendanceSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        CommonOutPutExcelComponent
    },
    watch: {},
}
</script>

<style lang='less'></style>