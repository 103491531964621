import request from '../request'

//月考勤列表
export const attendance_index_request = p => {
    return request({
        method:'GET',
        url:'attendance/index',
        params: p
    })
}

//导入月考勤
export const attendance_excel_add_request = d => {
    return request({
        method:'POST',
        url:'attendance/excel_add',
        data: d
    })
}

//导出约考勤
export const out_excel_request = p => {
    return request({
        method:'GET',
        url:'attendance/out_excel',
        params: p
    })
}